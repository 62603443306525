.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  width: 80%;
}

.aboutPic {
  display: flex;
  height: 50vh;
  border-radius: 50%;
  border: 1px solid var(--highlight);
  box-shadow: 1px 2px 2px var(--shadow);
  background-color: white;
  margin: 1rem;
}

.socialIconList {
  display: flex;
  flex-direction: row;
  margin: 1rem;
  justify-content: center;
  align-items: center;
}

.socialLink {
  margin: 0.5rem;
  color: var(--highlight);
  border-radius: 6px;
  padding: 0.2rem;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 1rem;
}

.heading {
  display: flex;
  margin: 0 0.5rem;
}

.label {
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem 0 0 0;
  font-size: 1.1rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formField {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 0.5rem;
}

.nameInput, .emailInput {
  height: 2rem;
  font-family: "News Cycle", sans-serif;
  font-size: 1.1rem;
}

.nameInput:focus, .emailInput:focus, .msgInput:focus {
  outline-color: var(--highlight);
}

.msgInput {
  height: 10rem;
  font-family: "News Cycle", sans-serif;
  font-size: 1.1rem;
}

.submitBtn {
  font-size: 1.2rem;
  font-weight: bolder;
  margin: 1rem;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 4px var(--shadow);
  color: white;
  background-color: var(--highlight);
}

.submitBtn:active {
  box-shadow: none;
}

/* MEDIA QUERIES */
@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
  }
}