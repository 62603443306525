.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  padding: 0 0 100px 0;
}

a {
  text-decoration: none;
  color: var(--nav);
}

footer {
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  text-align: center;
  position: absolute;
}

section {
  -webkit-animation: fade-in 1.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}