.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.bgimg {
  display: flex;
  width: 25%;
  min-width: 200px;
  aspect-ratio: 1/1;
  object-fit: contain;
  margin: 2rem 0 0 0;
}

.eduList {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-content: center;
  align-items: center;
}

.eduCard {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  max-width: 800px;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--shadow);
  border-radius: 6px;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  box-shadow: 1px 1px 2px var(--shadow);
}

.listTitle {
  display: flex;
  width: 100%;
  margin: 3rem 0 0.5rem 0;
  color: var(--highlight);
  font-size: 1.6rem;
  text-decoration: underline;
}

.edulogo {
  display: flex;
  height: 7rem;
  max-width: 50%;
  object-fit: contain;
  border: 1px solid var(--shadow);
  border-radius: 6px;
  padding: 0.2rem;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

h3 {
  margin: 0.5rem 0;
}

p {
  margin: 0.5rem;
}