.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
}

.filter {
  display: flex;
  margin: 0.5rem;
  border: none;
  border-radius: 6px;
  padding: 0.5rem;
  font-size: 0.9rem;
  background-color: white;
  min-width: 4rem;
  justify-content: center;
  box-shadow: 1px 1px 2px var(--shadow);
}

.selected {
  background-color: var(--highlight);
  color: white;
}

.filter:hover {
  transform: scale(1.05);
}

.filter:active {
  box-shadow: none;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  width: 100%;
}

.listTitle {
  display: flex;
  width: 90%;
  margin: 1rem 0 1rem 0;
  color: var(--highlight);
  font-size: 1.6rem;
  text-decoration: underline;
}

.loreimg {
  display: flex;
  width: 100%;
  height: 100%;
}

.pdfDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  min-width: 400px;
  margin: 0 0.5rem 1rem 0.5rem;
}

.pdfView {
  width: 90%;
  height: 400px;
}