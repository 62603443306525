.main {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.bgPic {
  display: flex;
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  object-fit: contain;
  margin: 3rem 0 0 0;
  border: 3px solid white;
  border-radius: 6px;
  opacity: 0.7;
}