.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.listTitle {
  display: flex;
  width: 100%;
  margin: 2.5rem 0 0.5rem 0;
  color: var(--highlight);
  font-size: 1.6rem;
  text-decoration: underline;
}

.teachList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.teachCard {
  display: flex;
  border: 1px solid var(--shadow);
  border-radius: 6px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 1px 1px 2px var(--shadow);
  padding: 0.7rem 1rem;
  margin: 0.5rem;
  height: auto;
}

.logo {
  display: flex;
  width: 80px;
  border: 1px solid var(--shadow);
  padding: 0.2rem;
  margin: 0 0 0.2rem 0;
}

h2 {
  margin: 0.2rem;
}

h4, h5 {
  margin: 0.1rem;
}

.schoolDeets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.jobDeets {
  display: flex;
  flex-direction: column;
  list-style: none;
  text-align: start;
  margin: 0.5rem 1rem;
  min-width: 50%;
}

.slideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
}

.slideList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.slides {
  display: flex;
  min-width: 300px;
  width: 45%;
  height: 40vh;
  margin: 1rem;
}

.evalContainer {
  display: flex;
  flex-direction: column;
  width: 96%;
}

.evalTicker {
  display: flex;
  width: 100%;
  align-items: start;
}

.evalImg {
  display: flex;
  width: 25%;
  min-width: 80px;
  object-fit: contain;
}

.marquee {
  display: flex;
  border: 1px solid var(--shadow);
  border-radius: 6px;
  box-shadow: 1px 1px 2px var(--shadow);
  padding: 1rem;
  width: 75%;
  background-color: white;
}

.marqueeText {
  display: flex;
  font-size: 1rem;
  max-width: 300px;
  text-align: left;
  margin: 0 1rem;
}

@media screen and (max-width: 480px) {
  .marqueeText {
    font-size: 0.8rem;
    width: 200px;
  }
}

@media screen and (max-width: 700px) {
  .teachList {
    flex-direction: column;
  }
  .teachCard {
    flex-direction: column;
    width: 100%;
  }
  .slides {
    width: 100%;
  }
  .schoolDeets {
    width: 100%;
  }
  .jobDeets {
    margin: 1rem 0 0 0;
  }
}

