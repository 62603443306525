img {
  pointer-events: all;
}

.artCard {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  min-width: 200px;
  max-height: 400px;
  object-fit: contain;
  margin: 0.5rem;
  border: 1px solid var(--shadow);
  box-shadow: 1px 1px 2px var(--shadow);
  background-color: white;
  cursor: pointer;
}

.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup {
  position: fixed;
  top: 1rem;
  padding: 1rem;
  border: 2px solid black;
  background-color: white;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 2px 2px 4px var(--shadow);

}
.popupImage {
  max-width: 100%;
  max-height: 90vh;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


@media screen and (max-width: 480px) {
  .artCard {
    width: 80%;
  }

  .popup {
    display: none;
  }
}


.overlay {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}