.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: var(--nav);
  margin: 0;
  top: 0;
}

.links {
  margin: 0 0.5rem;
}

.links:hover{
  text-decoration: underline;
}